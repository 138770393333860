import React from "react";
import { useEffect } from 'react';
import { navigate } from 'gatsby';

const App = () => {
  useEffect(() => {
    navigate('/');
  }, []);

  return null;
};

export default App;
